<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="grid1"
        title="용수 공급원별 사용량"
        tableId="grid1"
        :columns="grid1.columns"
        :gridHeight="grid1.height"
        :data="grid1.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        :gridHeightAuto="param.isFullScreen"
        @table-data-change="tableDataChange1"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData1"/>
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="grid2"
        title="용수 공급원별 폐수배출량"
        tableId="grid2"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        :gridHeightAuto="param.isFullScreen"
        @table-data-change="tableDataChange2"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData2"/>
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-report-03',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envWaterDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'envWaterMstSupwaterName',
            field: 'envWaterMstSupwaterName',
            label: '용수구분명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'prevScore',
            field: 'prevScore',
            label: '전일지침(㎥)',
            align: 'center',
            type: 'number',
            style: 'width:16%',
            sortable: false,
          },
          {
            name: 'nowScore',
            field: 'nowScore',
            label: '금일지침(㎥)',
            align: 'center',
            type: 'number',
            style: 'width:16%',
            sortable: false,
          },
          {
            name: 'todayScore',
            field: 'todayScore',
            label: '사용량(㎥/일)',
            align: 'center',
            type: 'number',
            style: 'width:16%',
            sortable: false,
          },
          {
            name: 'todayTime',
            field: 'todayTime',
            label: '검침시간대',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:16%',
            sortable: false,
          },
        ],
        data: [],
        height: '600px',
      },
      grid2: {
        columns: [
          {
            name: 'envWaterMstDiswaterName',
            field: 'envWaterMstDiswaterName',
            label: '용수구분명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'prevScore',
            field: 'prevScore',
            label: '전일지침(㎥)',
            align: 'center',
            type: 'number',
            style: 'width:22%',
            sortable: false,
          },
          {
            name: 'nowScore',
            field: 'nowScore',
            label: '금일지침(㎥)',
            align: 'center',
            type: 'number',
            style: 'width:22%',
            sortable: false,
          },
          {
            name: 'todayScore',
            field: 'todayScore',
            label: '배출량 및 사용량(㎥/일)',
            align: 'center',
            type: 'number',
            style: 'width:22%',
            sortable: false,
          },
        ],
        data: [],
        height: '600px',
      },
      listUrl1: '',
      saveUrl1: '',
      listUrl2: '',
      saveUrl2: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl1 = selectConfig.env.water.daily.supwater.list.url;
      this.saveUrl1 = transactionConfig.env.water.daily.supwater.save.url;
      this.listUrl2 = selectConfig.env.water.daily.diswater.list.url;
      this.saveUrl2 = transactionConfig.env.water.daily.diswater.save.url;
      this.getList1();
      this.getList2();
    },
    getList1() {
      this.$http.url = this.listUrl1;
      this.$http.type = 'GET';
      this.$http.param = {envWaterDailyResultId: this.param.envWaterDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    getList2() {
      this.$http.url = this.listUrl2;
      this.$http.type = 'GET';
      this.$http.param = {envWaterDailyResultId: this.param.envWaterDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    saveData1() {
      let saveData = this.grid1.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl1;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList1();
              window.getApp.$emit('ALERT', {
                title: '안내 ', 
                message: '저장되었습니다.', 
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    saveData2() {
      let saveData = this.grid2.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl2;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList2();
              window.getApp.$emit('ALERT', {
                title: '안내 ', 
                message: '저장되었습니다.', 
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    tableDataChange1(props) {
      if (props.row['prevScore'] > 0 && props.row['nowScore'] > 0) {
        this.$set(props.row, 'todayScore', (Math.round((props.row['nowScore'] - props.row['prevScore'])*100)/100));
      } else if ((props.row['prevScore'] == 0 || props.row['prevScore'] === null) && props.row['nowScore'] > 0) {
        this.$set(props.row, 'todayScore', (Math.round(props.row['nowScore']*100)/100));
      }
    },
    tableDataChange2(props) {
      if (props.row['prevScore'] > 0 && props.row['nowScore'] > 0) {
        this.$set(props.row, 'todayScore', (Math.round((props.row['nowScore'] - props.row['prevScore'])*100)/100));
      } else if ((props.row['prevScore'] == 0 || props.row['prevScore'] === null) && props.row['nowScore'] > 0) {
        this.$set(props.row, 'todayScore', (Math.round(props.row['nowScore']*100)/100));
      }
    },
  }
};
</script>
